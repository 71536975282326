<!--  处理学生留言-->
<template>
    <div class="StudentMsg">
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form"  label-width="100px">
            <el-form-item label="考试日期">
                <el-date-picker v-model="form.testDate" type="daterange"
                range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键词"></el-input>
            </el-form-item>
            <el-form-item label="选择">
                <el-select v-model="form.mechanism" placeholder="请选择">
                    <el-option
                      v-for="item in form.choice"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <div class="" style="margin-right: 10px;margin-top: 5px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                    重置
                </el-button>
            </div>
        </el-form>
        <el-divider></el-divider>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="700" id="out-table" @sort-change="abc">
            <!-- 复选按钮 -->
            <el-table-column type="index" label="序号" align="center" width="100">
            </el-table-column>
            <el-table-column prop="status" label="状态" :sortable="true" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==1">发布</span>
                    <span v-else-if="scope.row.status==2">归档</span>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="客户名称" :sortable="true" align="center" width="150">
            </el-table-column>
            <el-table-column prop="add_time" label="发布日期" :sortable="true" align="center" width="200">
            </el-table-column>
            <el-table-column prop="type" label="评价等级" :sortable="true" align="center" width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.type==1">好评</span>
                    <span v-else-if="scope.row.type==2">中评</span>
                    <span v-else-if="scope.row.type==3">差评</span>
                </template>
            </el-table-column>
            <el-table-column prop="content" label="标题" :sortable="true" align="center" width="350"
                :show-overflow-tooltip="true">
            </el-table-column>
            <!-- <el-table-column prop="" label="入学年份" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" label="年级" align="center" width="100">
            </el-table-column> -->
            <!-- <el-table-column prop="add_time" label="录入时间" align="center" width="200">
            </el-table-column>
            <el-table-column prop="" label="修改人" align="center" width="150">
            </el-table-column>
            <el-table-column prop="update_time" label="最近修改时间" align="center" width="200">
            </el-table-column> -->
            <el-table-column label="操作" align="center"  width="120" >
                <template slot-scope="scope">
                    <!-- <el-button icon="el-icon-edit" size="mini" style="background: #18BC9C;color: #fff;" @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button> -->
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="delt(scope.$index,scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange"
         @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   testDate:"",//考试日期
                   search:"",// 搜索
                   mechanism:"",//选择
                   choice:[
                       {value:"0",label:"全部"},
                       {value:"1",label:"学生"},
                       {value:"2",label:"机构"}
                   ]//选择选项
                },
                tableData:[],
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
                arr:[]
            }
        },
        created() {
            this.getList()
        },
        methods:{
            getList(){
                this.$request({
                    url: "/api/message/list",
                    method: "POST",
                    data: {
                        page: this.currentPage,
                        limit: this.currentLimit,
                        id_type:this.form.mechanism,
                        search:this.form.search,
                        dateQuery:this.form.testDate,
                        sort:this.arr
                    }
                }).then(res=>{
                    console.log(res)
                    if(res.code==1){
                        this.tableData=res.data.list
                        this.total=res.data.count
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            query(){//查询
                this.currentPage=1
                this.getList()
            },
            Reset(){//重置
                this.form.mechanism=''
                this.form.search=''
                this.form.testDate=''
                this.getList()
            },
            edit(index,row){//编辑
                this.$router.push({
                    path: "editStudentMsg", 
                })
            },
            delt(index,row){//删除
                this.$confirm('是否删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    this.$request({
                        url: '/api/message/del',
                        method: 'POST',
                        data: {
                            id: row.id
                        }
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.tableData.splice(index, 1)
                            this.getList()
                        }
                    })
                })
            },
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }
    }
</script>

<style scoped="scoped">
    .StudentMsg{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
    }
    
    .form ::v-deep .el-input__inner {
        width: 300px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    .form .el-select{
        width: 300px;
        height: 37px;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 29px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
